var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"application","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"grey--text"},[_c('v-icon',[_vm._v("mdi-file")]),_vm._v(" NEW APPLICATION ")],1)],1),_c('v-card-text',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_c('v-icon',{attrs:{"medium":"","left":""}},[_vm._v("mdi-twitter")]),_c('span',{staticClass:"display-2 font-weight-light"},[_vm._v(" Electricity Service Detail ")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"account_type","name":"Account Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"error-messages":errors,"row":""},model:{value:(_vm.form.account_type),callback:function ($$v) {_vm.$set(_vm.form, "account_type", $$v)},expression:"form.account_type"}},[_c('v-radio',{attrs:{"label":"Residential","value":"R"}}),_c('v-radio',{attrs:{"label":"Business","value":"B"}})],1)]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"ICP identifier","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"ICP identifier","error-messages":errors},model:{value:(_vm.form.icpnum),callback:function ($$v) {_vm.$set(_vm.form, "icpnum", $$v)},expression:"form.icpnum"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Physical address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Physical address","error-messages":errors},model:{value:(_vm.form.physical_address),callback:function ($$v) {_vm.$set(_vm.form, "physical_address", $$v)},expression:"form.physical_address"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"is_same_as_physical","name":"same as physical address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"row":"","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("is same as physical address?")])]},proxy:true}],null,true),model:{value:(_vm.form.is_same_as_physical),callback:function ($$v) {_vm.$set(_vm.form, "is_same_as_physical", $$v)},expression:"form.is_same_as_physical"}},[_c('v-radio',{attrs:{"label":"Yes","value":"1"}}),_c('v-radio',{attrs:{"label":"No","value":"0"}})],1)]}}],null,true)})],1)],1),(_vm.showPostal)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Postal address","rules":"required_if:is_same_as_physical,0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Postal address","error-messages":errors},model:{value:(_vm.form.postal_address),callback:function ($$v) {_vm.$set(_vm.form, "postal_address", $$v)},expression:"form.postal_address"}})]}}],null,true)})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Energy user type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"row":"","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("Energy User Type")])]},proxy:true}],null,true),model:{value:(_vm.form.plan),callback:function ($$v) {_vm.$set(_vm.form, "plan", $$v)},expression:"form.plan"}},[_c('v-radio',{attrs:{"label":"Standard User Plan","value":"S"}}),_c('v-radio',{attrs:{"label":"Low User Plan","value":"L"}})],1)]}}],null,true)})],1)],1),(_vm.showRates)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"Daily rate","rules":"required_if:account_type,B"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Daily rate","error-messages":errors},model:{value:(_vm.form.daily_rate),callback:function ($$v) {_vm.$set(_vm.form, "daily_rate", $$v)},expression:"form.daily_rate"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"Volume rate","rules":"required_if:account_type,B"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Volume rate","error-messages":errors},model:{value:(_vm.form.volume_rate),callback:function ($$v) {_vm.$set(_vm.form, "volume_rate", $$v)},expression:"form.volume_rate"}})]}}],null,true)})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Switch type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"column":"","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("What is your situation?")])]},proxy:true}],null,true),model:{value:(_vm.form.switch_type),callback:function ($$v) {_vm.$set(_vm.form, "switch_type", $$v)},expression:"form.switch_type"}},[_c('v-radio',{attrs:{"label":"I am moving in or just moved in","value":"MI"}}),_c('v-radio',{attrs:{"label":"I want to switch to Stack Energy from current service provider","value":"TR"}})],1)]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Book date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Book date","error-messages":errors},model:{value:(_vm.form.book_date),callback:function ($$v) {_vm.$set(_vm.form, "book_date", $$v)},expression:"form.book_date"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Have dog","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"row":"","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("Do you have a dog?")])]},proxy:true}],null,true),model:{value:(_vm.form.have_dog_flag),callback:function ($$v) {_vm.$set(_vm.form, "have_dog_flag", $$v)},expression:"form.have_dog_flag"}},[_c('v-radio',{attrs:{"label":"Yes","value":"1"}}),_c('v-radio',{attrs:{"label":"No","value":"0"}})],1)]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Medical support flag","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"row":"","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" Do you need electricity for medical support? ")])]},proxy:true}],null,true),model:{value:(_vm.form.medical_support_flag),callback:function ($$v) {_vm.$set(_vm.form, "medical_support_flag", $$v)},expression:"form.medical_support_flag"}},[_c('v-radio',{attrs:{"label":"Yes","value":"1"}}),_c('v-radio',{attrs:{"label":"No","value":"0"}})],1)]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Vulnerable flag","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"row":"","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" Do you have anyone at the premise a vulnerable person? ")])]},proxy:true}],null,true),model:{value:(_vm.form.vulnerable_flag),callback:function ($$v) {_vm.$set(_vm.form, "vulnerable_flag", $$v)},expression:"form.vulnerable_flag"}},[_c('v-radio',{attrs:{"label":"Yes","value":"1"}}),_c('v-radio',{attrs:{"label":"No","value":"0"}})],1)]}}],null,true)})],1)],1)],1)],1),_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_c('v-icon',{attrs:{"medium":"","left":""}},[_vm._v("mdi-account")]),_c('span',{staticClass:"display-2 font-weight-light"},[_vm._v(" Personal Detail ")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"is_new","name":"Is New","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"row":"","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("Is new customer?")])]},proxy:true}],null,true),model:{value:(_vm.form.is_new),callback:function ($$v) {_vm.$set(_vm.form, "is_new", $$v)},expression:"form.is_new"}},[_c('v-radio',{attrs:{"label":"New customer","value":"1"}}),_c('v-radio',{attrs:{"label":"Existing customer","value":"0"}})],1)]}}],null,true)})],1)],1),(_vm.showCustomerNo)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Customer No","rules":"required_if:is_new,0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Customer No","error-messages":errors},model:{value:(_vm.form.customer_no),callback:function ($$v) {_vm.$set(_vm.form, "customer_no", $$v)},expression:"form.customer_no"}})]}}],null,true)})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"First name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"First name","error-messages":errors},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Middle name"},model:{value:(_vm.form.middle_name),callback:function ($$v) {_vm.$set(_vm.form, "middle_name", $$v)},expression:"form.middle_name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Last name","error-messages":errors},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Full name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Fullname","error-messages":errors},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email","error-messages":errors},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Dob","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"DOB","error-messages":errors},model:{value:(_vm.form.dob),callback:function ($$v) {_vm.$set(_vm.form, "dob", $$v)},expression:"form.dob"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Mobile","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Mobile number","error-messages":errors},model:{value:(_vm.form.mobile),callback:function ($$v) {_vm.$set(_vm.form, "mobile", $$v)},expression:"form.mobile"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Phone number"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Joint account","vid":"is_joint","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"row":"","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("is joint account?")])]},proxy:true}],null,true),model:{value:(_vm.form.is_joint),callback:function ($$v) {_vm.$set(_vm.form, "is_joint", $$v)},expression:"form.is_joint"}},[_c('v-radio',{attrs:{"label":"Yes","value":"1"}}),_c('v-radio',{attrs:{"label":"No","value":"0"}})],1)]}}],null,true)})],1)],1),(_vm.showJoint)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Joint name","rules":"required_if:is_joint,1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Joint Name","error-messages":errors},model:{value:(_vm.form.joint_name),callback:function ($$v) {_vm.$set(_vm.form, "joint_name", $$v)},expression:"form.joint_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Joint contact","rules":"required_if:is_joint,1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Joint Contact","error-messages":errors},model:{value:(_vm.form.joint_contact),callback:function ($$v) {_vm.$set(_vm.form, "joint_contact", $$v)},expression:"form.joint_contact"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Joint dob","rules":"required_if:is_joint,1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Joint DOB","error-messages":errors},model:{value:(_vm.form.joint_dob),callback:function ($$v) {_vm.$set(_vm.form, "joint_dob", $$v)},expression:"form.joint_dob"}})]}}],null,true)})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Reference Customer Number"},model:{value:(_vm.form.referral),callback:function ($$v) {_vm.$set(_vm.form, "referral", $$v)},expression:"form.referral"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Credit Check Method","vid":"credit_check_method","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"row":"","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("Preferred credit check methods")])]},proxy:true}],null,true),model:{value:(_vm.form.credit_check_method),callback:function ($$v) {_vm.$set(_vm.form, "credit_check_method", $$v)},expression:"form.credit_check_method"}},[_c('v-radio',{attrs:{"label":"Driver License","value":"DL"}}),_c('v-radio',{attrs:{"label":"Passport","value":"PP"}}),_c('v-radio',{attrs:{"label":"Other identification","value":"OT"}})],1)]}}],null,true)})],1)],1),(_vm.showDriverLicense)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Driver License 5a","rules":"required_if:credit_check_method,DL"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Driver License 5a","error-messages":errors},model:{value:(_vm.form.driver_license_5a),callback:function ($$v) {_vm.$set(_vm.form, "driver_license_5a", $$v)},expression:"form.driver_license_5a"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Driver License 5b","rules":"required_if:credit_check_method,DL"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Driver License 5b","error-messages":errors},model:{value:(_vm.form.driver_license_5b),callback:function ($$v) {_vm.$set(_vm.form, "driver_license_5b", $$v)},expression:"form.driver_license_5b"}})]}}],null,true)})],1)],1):_vm._e(),(_vm.showPassport)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Passport No","rules":"required_if:credit_check_method,PP"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Passport Number","error-messages":errors},model:{value:(_vm.form.passport_no),callback:function ($$v) {_vm.$set(_vm.form, "passport_no", $$v)},expression:"form.passport_no"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Passport Nationality","rules":"required_if:credit_check_method,PP"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Passport Nationality","error-messages":errors},model:{value:(_vm.form.passport_nationality),callback:function ($$v) {_vm.$set(_vm.form, "passport_nationality", $$v)},expression:"form.passport_nationality"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Passport Expiry","rules":"required_if:credit_check_method,PP"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Passport Expiry","error-messages":errors},model:{value:(_vm.form.passport_expiry),callback:function ($$v) {_vm.$set(_vm.form, "passport_expiry", $$v)},expression:"form.passport_expiry"}})]}}],null,true)})],1)],1):_vm._e(),(_vm.showOtherIdentification)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Other identification","rules":"required_if:credit_check_method,OT"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Other Identification","error-messages":errors},model:{value:(_vm.form.other_identification),callback:function ($$v) {_vm.$set(_vm.form, "other_identification", $$v)},expression:"form.other_identification"}})]}}],null,true)})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Agree Credit Check","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"value":"1","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" I give permission to use the personal information I have provided for credit checking processes ")])]},proxy:true}],null,true),model:{value:(_vm.form.agree_credit_check_flag),callback:function ($$v) {_vm.$set(_vm.form, "agree_credit_check_flag", $$v)},expression:"form.agree_credit_check_flag"}})]}}],null,true)})],1)],1)],1)],1),_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_c('v-icon',{attrs:{"medium":"","left":""}},[_vm._v("mdi-credit-card")]),_c('span',{staticClass:"display-2 font-weight-light"},[_vm._v(" Billing & Payment Detail ")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Billing cycle","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"row":"","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("Preferred billing cycle")])]},proxy:true}],null,true),model:{value:(_vm.form.billing_cycle),callback:function ($$v) {_vm.$set(_vm.form, "billing_cycle", $$v)},expression:"form.billing_cycle"}},[_c('v-radio',{attrs:{"label":"Weekly","value":"W"}}),_c('v-radio',{attrs:{"label":"Monthly","value":"M"}})],1)]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Payment method","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"column":"","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" Which of the following payment methods do you prefer? ")])]},proxy:true}],null,true),model:{value:(_vm.form.payment_method),callback:function ($$v) {_vm.$set(_vm.form, "payment_method", $$v)},expression:"form.payment_method"}},[_c('v-radio',{attrs:{"label":"Direct Debit","value":"DD","disabled":""}}),_c('v-radio',{attrs:{"label":"Credit Card","value":"CC"}}),_c('v-radio',{attrs:{"label":"Direct Credit","value":"DC"}}),_c('v-radio',{attrs:{"label":"Cheque","value":"CQ","disabled":""}})],1)]}}],null,true)})],1)],1)],1)],1),_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_c('v-icon',{attrs:{"medium":"","left":""}},[_vm._v("mdi-file")]),_c('span',{staticClass:"display-2 font-weight-light"},[_vm._v(" Agreement & Submit ")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Agree tnc","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"value":"1","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" I accept Stack Energy terms and conditions to process this application ")])]},proxy:true}],null,true),model:{value:(_vm.form.agree_tnc_flag),callback:function ($$v) {_vm.$set(_vm.form, "agree_tnc_flag", $$v)},expression:"form.agree_tnc_flag"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Agree Privacy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"value":"1","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("I accept Stack Energy privacy policy")])]},proxy:true}],null,true),model:{value:(_vm.form.agree_privacy_flag),callback:function ($$v) {_vm.$set(_vm.form, "agree_privacy_flag", $$v)},expression:"form.agree_privacy_flag"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-0",attrs:{"color":"success","type":"submit"}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }