<template>
  <v-container id="application" fluid tag="section">
    <v-row>
      <v-col cols="12">
        <v-card class="mx-auto">
          <v-toolbar flat>
            <v-toolbar-title class="grey--text">
              <v-icon>mdi-file</v-icon>
              NEW APPLICATION
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
              <v-form @submit.prevent="handleSubmit(submit)">
                <v-card class="mx-auto">
                  <v-card-title>
                    <v-icon medium left>mdi-twitter</v-icon>
                    <span class="display-2 font-weight-light">
                      Electricity Service Detail
                    </span>
                  </v-card-title>

                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="6">
                        <validation-provider
                          v-slot="{ errors }"
                          vid="account_type"
                          name="Account Type"
                          rules="required"
                        >
                          <v-radio-group
                            v-model="form.account_type"
                            :error-messages="errors"
                            row
                          >
                            <v-radio label="Residential" value="R" />
                            <v-radio label="Business" value="B" />
                          </v-radio-group>
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="6">
                        <validation-provider
                          v-slot="{ errors }"
                          name="ICP identifier"
                          rules="required"
                        >
                          <v-text-field
                            v-model="form.icpnum"
                            label="ICP identifier"
                            :error-messages="errors"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Physical address"
                          rules="required"
                        >
                          <v-text-field
                            v-model="form.physical_address"
                            label="Physical address"
                            :error-messages="errors"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="6">
                        <validation-provider
                          v-slot="{ errors }"
                          vid="is_same_as_physical"
                          name="same as physical address"
                          rules="required"
                        >
                          <v-radio-group
                            v-model="form.is_same_as_physical"
                            row
                            :error-messages="errors"
                          >
                            <template v-slot:label>
                              <div>is same as physical address?</div>
                            </template>
                            <v-radio label="Yes" value="1" />
                            <v-radio label="No" value="0" />
                          </v-radio-group>
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row v-if="showPostal">
                      <v-col cols="12">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Postal address"
                          rules="required_if:is_same_as_physical,0"
                        >
                          <v-text-field
                            v-model="form.postal_address"
                            label="Postal address"
                            :error-messages="errors"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Energy user type"
                          rules="required"
                        >
                          <v-radio-group
                            v-model="form.plan"
                            row
                            :error-messages="errors"
                          >
                            <template v-slot:label>
                              <div>Energy User Type</div>
                            </template>
                            <v-radio label="Standard User Plan" value="S" />
                            <v-radio label="Low User Plan" value="L" />
                          </v-radio-group>
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row v-if="showRates">
                      <v-col cols="12" md="3">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Daily rate"
                          rules="required_if:account_type,B"
                        >
                          <v-text-field
                            v-model="form.daily_rate"
                            label="Daily rate"
                            :error-messages="errors"
                          />
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" md="3">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Volume rate"
                          rules="required_if:account_type,B"
                        >
                          <v-text-field
                            v-model="form.volume_rate"
                            label="Volume rate"
                            :error-messages="errors"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Switch type"
                          rules="required"
                        >
                          <v-radio-group
                            v-model="form.switch_type"
                            column
                            :error-messages="errors"
                          >
                            <template v-slot:label>
                              <div>What is your situation?</div>
                            </template>
                            <v-radio
                              label="I am moving in or just moved in"
                              value="MI"
                            />
                            <v-radio
                              label="I want to switch to Stack Energy from current service provider"
                              value="TR"
                            />
                          </v-radio-group>
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="6">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Book date"
                          rules="required"
                        >
                          <v-text-field
                            v-model="form.book_date"
                            label="Book date"
                            :error-messages="errors"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Have dog"
                          rules="required"
                        >
                          <v-radio-group
                            v-model="form.have_dog_flag"
                            row
                            :error-messages="errors"
                          >
                            <template v-slot:label>
                              <div>Do you have a dog?</div>
                            </template>
                            <v-radio label="Yes" value="1" />
                            <v-radio label="No" value="0" />
                          </v-radio-group>
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Medical support flag"
                          rules="required"
                        >
                          <v-radio-group
                            v-model="form.medical_support_flag"
                            row
                            :error-messages="errors"
                          >
                            <template v-slot:label>
                              <div>
                                Do you need electricity for medical support?
                              </div>
                            </template>
                            <v-radio label="Yes" value="1" />
                            <v-radio label="No" value="0" />
                          </v-radio-group>
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Vulnerable flag"
                          rules="required"
                        >
                          <v-radio-group
                            v-model="form.vulnerable_flag"
                            row
                            :error-messages="errors"
                          >
                            <template v-slot:label>
                              <div>
                                Do you have anyone at the premise a vulnerable person?
                              </div>
                            </template>
                            <v-radio label="Yes" value="1" />
                            <v-radio label="No" value="0" />
                          </v-radio-group>
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <v-card class="mx-auto">
                  <v-card-title>
                    <v-icon medium left>mdi-account</v-icon>
                    <span class="display-2 font-weight-light">
                      Personal Detail
                    </span>
                  </v-card-title>

                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <validation-provider
                          v-slot="{ errors }"
                          vid="is_new"
                          name="Is New"
                          rules="required"
                        >
                          <v-radio-group
                            v-model="form.is_new"
                            row
                            :error-messages="errors"
                          >
                            <template v-slot:label>
                              <div>Is new customer?</div>
                            </template>
                            <v-radio label="New customer" value="1" />
                            <v-radio label="Existing customer" value="0" />
                          </v-radio-group>
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row v-if="showCustomerNo">
                      <v-col cols="12" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Customer No"
                          rules="required_if:is_new,0"
                        >
                          <v-text-field
                            v-model="form.customer_no"
                            label="Customer No"
                            :error-messages="errors"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="First name"
                          rules="required"
                        >
                          <v-text-field
                            v-model="form.first_name"
                            label="First name"
                            :error-messages="errors"
                          />
                        </validation-provider>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="form.middle_name"
                          label="Middle name"
                        />
                      </v-col>

                      <v-col cols="12" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Last name"
                          rules="required"
                        >
                          <v-text-field
                            v-model="form.last_name"
                            label="Last name"
                            :error-messages="errors"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="6">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Full name"
                          rules="required"
                        >
                          <v-text-field
                            v-model="form.name"
                            label="Fullname"
                            :error-messages="errors"
                          />
                        </validation-provider>
                      </v-col>

                      <v-col cols="12" md="6">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Email"
                          rules="required|email"
                        >
                          <v-text-field
                            v-model="form.email"
                            label="Email"
                            :error-messages="errors"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Dob"
                          rules="required"
                        >
                          <v-text-field
                            v-model="form.dob"
                            label="DOB"
                            :error-messages="errors"
                          />
                        </validation-provider>
                      </v-col>

                      <v-col cols="12" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Mobile"
                          rules="required"
                        >
                          <v-text-field
                            v-model="form.mobile"
                            label="Mobile number"
                            :error-messages="errors"
                          />
                        </validation-provider>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="form.phone"
                          label="Phone number"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Joint account"
                          vid="is_joint"
                          rules="required"
                        >
                          <v-radio-group
                            v-model="form.is_joint"
                            row
                            :error-messages="errors"
                          >
                            <template v-slot:label>
                              <div>is joint account?</div>
                            </template>
                            <v-radio label="Yes" value="1" />
                            <v-radio label="No" value="0" />
                          </v-radio-group>
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row v-if="showJoint">
                      <v-col cols="12" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Joint name"
                          rules="required_if:is_joint,1"
                        >
                          <v-text-field
                            v-model="form.joint_name"
                            label="Joint Name"
                            :error-messages="errors"
                          />
                        </validation-provider>
                      </v-col>

                      <v-col cols="12" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Joint contact"
                          rules="required_if:is_joint,1"
                        >
                          <v-text-field
                            v-model="form.joint_contact"
                            label="Joint Contact"
                            :error-messages="errors"
                          />
                        </validation-provider>
                      </v-col>

                      <v-col cols="12" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Joint dob"
                          rules="required_if:is_joint,1"
                        >
                          <v-text-field
                            v-model="form.joint_dob"
                            label="Joint DOB"
                            :error-messages="errors"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="form.referral"
                          label="Reference Customer Number"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Credit Check Method"
                          vid="credit_check_method"
                          rules="required"
                        >
                          <v-radio-group
                            v-model="form.credit_check_method"
                            row
                            :error-messages="errors"
                          >
                            <template v-slot:label>
                              <div>Preferred credit check methods</div>
                            </template>
                            <v-radio label="Driver License" value="DL" />
                            <v-radio label="Passport" value="PP" />
                            <v-radio label="Other identification" value="OT" />
                          </v-radio-group>
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row v-if="showDriverLicense">
                      <v-col cols="12" md="6">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Driver License 5a"
                          rules="required_if:credit_check_method,DL"
                        >
                          <v-text-field
                            v-model="form.driver_license_5a"
                            label="Driver License 5a"
                            :error-messages="errors"
                          />
                        </validation-provider>
                      </v-col>

                      <v-col cols="12" md="6">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Driver License 5b"
                          rules="required_if:credit_check_method,DL"
                        >
                          <v-text-field
                            v-model="form.driver_license_5b"
                            label="Driver License 5b"
                            :error-messages="errors"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row v-if="showPassport">
                      <v-col cols="12" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Passport No"
                          rules="required_if:credit_check_method,PP"
                        >
                          <v-text-field
                            v-model="form.passport_no"
                            label="Passport Number"
                            :error-messages="errors"
                          />
                        </validation-provider>
                      </v-col>

                      <v-col cols="12" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Passport Nationality"
                          rules="required_if:credit_check_method,PP"
                        >
                          <v-text-field
                            v-model="form.passport_nationality"
                            label="Passport Nationality"
                            :error-messages="errors"
                          />
                        </validation-provider>
                      </v-col>

                      <v-col cols="12" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Passport Expiry"
                          rules="required_if:credit_check_method,PP"
                        >
                          <v-text-field
                            v-model="form.passport_expiry"
                            label="Passport Expiry"
                            :error-messages="errors"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row v-if="showOtherIdentification">
                      <v-col cols="12">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Other identification"
                          rules="required_if:credit_check_method,OT"
                        >
                          <v-text-field
                            v-model="form.other_identification"
                            label="Other Identification"
                            :error-messages="errors"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Agree Credit Check"
                          rules="required"
                        >
                          <v-checkbox
                            v-model="form.agree_credit_check_flag"
                            value="1"
                            :error-messages="errors"
                          >
                            <template v-slot:label>
                              <div>
                                I give permission to use the personal information I have provided for credit checking processes
                              </div>
                            </template>
                          </v-checkbox>
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <v-card class="mx-auto">
                  <v-card-title>
                    <v-icon medium left>mdi-credit-card</v-icon>
                    <span class="display-2 font-weight-light">
                      Billing &amp; Payment Detail
                    </span>
                  </v-card-title>

                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Billing cycle"
                          rules="required"
                        >
                          <v-radio-group
                            v-model="form.billing_cycle"
                            row
                            :error-messages="errors"
                          >
                            <template v-slot:label>
                              <div>Preferred billing cycle</div>
                            </template>
                            <v-radio label="Weekly" value="W" />
                            <v-radio label="Monthly" value="M" />
                          </v-radio-group>
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Payment method"
                          rules="required"
                        >
                          <v-radio-group
                            v-model="form.payment_method"
                            column
                            :error-messages="errors"
                          >
                            <template v-slot:label>
                              <div>
                                Which of the following payment methods do you prefer?
                              </div>
                            </template>
                            <v-radio label="Direct Debit" value="DD" disabled />
                            <v-radio label="Credit Card" value="CC" />
                            <v-radio label="Direct Credit" value="DC" />
                            <v-radio label="Cheque" value="CQ" disabled />
                          </v-radio-group>
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <v-card class="mx-auto">
                  <v-card-title>
                    <v-icon medium left>mdi-file</v-icon>
                    <span class="display-2 font-weight-light">
                      Agreement &amp; Submit
                    </span>
                  </v-card-title>

                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Agree tnc"
                          rules="required"
                        >
                          <v-checkbox
                            v-model="form.agree_tnc_flag"
                            value="1"
                            :error-messages="errors"
                          >
                            <template v-slot:label>
                              <div>
                                I accept Stack Energy terms and conditions to process this application
                              </div>
                            </template>
                          </v-checkbox>
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Agree Privacy"
                          rules="required"
                        >
                          <v-checkbox
                            v-model="form.agree_privacy_flag"
                            value="1"
                            :error-messages="errors"
                          >
                            <template v-slot:label>
                              <div>I accept Stack Energy privacy policy</div>
                            </template>
                          </v-checkbox>
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" class="text-right">
                        <v-btn color="success" class="mr-0" type="submit">
                          Submit
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'NewApplication',

  data: () => ({
    form: {
      account_type: null,
      switch_type: null,
      icpnum: null,
      physical_address: null,
      is_same_as_physical: null,
      postal_address: null,
      medical_support_flag: null,
      vulnerable_flag: null,
      plan: null,
      daily_rate: null,
      volume_rate: null,
      have_dog_flag: null,
      book_date: null,

      is_new: null,
      customer_no: null,
      name: null,
      first_name: null,
      middle_name: null,
      last_name: null,
      email: null,
      mobile: null,
      phone: null,
      dob: null,
      credit_check_method: null,
      driver_license_5a: null,
      driver_license_5b: null,
      passport_no: null,
      passport_nationality: null,
      passport_expiry: null,
      other_identification: null,
      agree_credit_check_flag: null,
      is_joint: null,
      joint_name: null,
      joint_contact: null,
      joint_dob: null,

      billing_cycle: null,
      payment_method: null,

      agree_tnc_flag: null,
      agree_privacy_flag: null,

      referral: null
    }
  }),

  computed: {
    showPostal () {
      return this.form.is_same_as_physical == '0' ? true : false
    },

    showRates () {
      return this.form.account_type == 'B' ? true : false
    },

    showCustomerNo () {
      return this.form.is_new == '0' ? true : false
    },

    showJoint () {
      return this.form.is_joint == '' ? true : false
    },

    showDriverLicense () {
      return this.form.credit_check_method == 'DL' ? true : false
    },

    showPassport () {
      return this.form.credit_check_method == 'PP' ? true : false
    },

    showOtherIdentification () {
      return this.form.credit_check_method == 'OT' ? true : false
    }
  },

  methods: {
    submit: function () {
      console.log(this.form)
      return new Promise((resolve, reject) => {
        this.$http.post('/api/applications', this.form)
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
}
</script>
